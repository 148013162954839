'use client'
import * as React from 'react'
import ReactDOM from 'react-dom'
import {
  AskCfGetTopicsQuery,
  AskCfHomeFeedQuery,
  useInfiniteAskCfHomeFeedQuery,
} from 'codegen/generated/ask-cf'
import { InfiniteData } from '@tanstack/react-query'

import { Feed } from '@ask-cf/components/feed'
import { MobileMenu } from '@ask-cf/components/mobile-menu'
import { FeedSortingProvider } from '@ask-cf/contexts/feed-sorting-context'
import { RightSidebar } from '@ask-cf/components/right-sidebar'
import { LFS_URL } from '@ask-cf/config'
import { Post } from '@ask-cf/components/feed/models'

export function MainPageContent({
  initialPosts,
  children,
  topicsQuery,
}: React.PropsWithChildren<{
  initialPosts: InfiniteData<AskCfHomeFeedQuery>
  topicsQuery: AskCfGetTopicsQuery
}>) {
  // @ts-expect-error
  ReactDOM.preconnect(LFS_URL)
  const { isLoading, data, hasNextPage, fetchNextPage } = useInfiniteAskCfHomeFeedQuery(
    {
      input: {
        currentPage: 1,
        itemsPerPage: 10,
      },
    },
    {
      initialData: initialPosts,
      getNextPageParam(_lastPage, allPages) {
        return {
          input: {
            currentPage: allPages.length + 1,
            itemsPerPage: 10,
          },
        }
      },
    },
  )

  const posts =
    (data?.pages
      .map(page => page.askCFHomeFeed?.questions)
      .flat()
      .filter(Boolean) as Post[]) || []

  return (
    <FeedSortingProvider>
      <div className="flex h-full w-full flex-col items-start py-6">
        <div className="flex w-full flex-row gap-6">
          <div className="flex w-full flex-col gap-6 xl:flex-row">
            <MobileMenu topicsQuery={topicsQuery} />
            {children}
            <div className="w-full lg:flex-grow" id="main-content">
              <Feed
                posts={posts}
                nextPage={fetchNextPage}
                loading={isLoading}
                hasMore={hasNextPage}
              />
            </div>
          </div>
          <RightSidebar />
        </div>
      </div>
    </FeedSortingProvider>
  )
}
