'use client'
import * as React from 'react'
import { AskCfGetTopicsQuery } from 'codegen/generated/ask-cf'

import { useDockBottom } from '@ask-cf/hooks/use-dock-bottom'
import { TopicsList } from '../topics-list'
import { GoogleAd } from '../google-ads'

export function LeftSidebar({
  children,
  topicsQuery,
}: React.PropsWithChildren<{ topicsQuery: AskCfGetTopicsQuery }>) {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const childRef = React.useRef<HTMLDivElement>(null)

  useDockBottom(parentRef, childRef)

  return (
    <div className="relative hidden w-[256px] shrink-0 self-stretch xl:block" ref={parentRef}>
      <div ref={childRef}>
        {children}
        <TopicsList topicsQuery={topicsQuery} />
        <div className="mt-4">
          <GoogleAd
            adClient="ca-pub-8093072971929641"
            adSlot="4900934274"
            style={{ display: 'inline-block', width: '250px', height: '250px' }}
          />
        </div>
      </div>
    </div>
  )
}
